import React from 'react'
import { Modal } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import {StyledModalV2, CompayContainerV2, StyledBodyV2, ShowPopupCard} from './styles';
import { ImageUrl } from '../../../utils/constants';
import LazyImage from '../../common/LazyImage/LazyImage';

class ShowAllCompanyInitiative extends React.Component {

  showWellnessInitiatives = (companyInitiatives) => (
    companyInitiatives && companyInitiatives.length && companyInitiatives.map((data, index) => (
      <CompayContainerV2 key={index}>
        <ShowPopupCard background={data.color} color={data.points_color} border={index===0?1:""}>
          <LazyImage div={'image'} src={`${ImageUrl}/${data.logo}`} />
          <div className={'popup-name'}>
            <div className={'name'}>{data.name}</div>
            <div className={'point'}>+{data.points} pts</div>
          </div>
          <div className={'percentage-main'}>
            <div className={'percentage'}>
              {data.percentage}%
            </div>
          </div>
        </ShowPopupCard>
      </CompayContainerV2>
    ))
  );

  render() {
    const { showModal,onClose,wellnessInitiatives} = this.props;
    return (
      <StyledModalV2 transform={1} show={showModal}    newWidth={"530px"}  newBg={'newBg'} paddingBottom="15px">
        <Modal.Header >
          <div className={'modal-container'}>
            <LazyImage onClick={() => onClose()} src={ImageUrl + "/images/AdminEngagement/Cross_Icon_Img.png"} alt="close" />
            <div>
              Company Initiatives
            </div>
          </div>
        </Modal.Header>
        <StyledBodyV2>
          {this.showWellnessInitiatives(wellnessInitiatives)}
        </StyledBodyV2>
      </StyledModalV2>    
    ) ;
  }

}  
ShowAllCompanyInitiative.propTypes = {
  showModal: PropTypes.bool.isRequired,
  wellnessInitiatives: PropTypes.array,
  onClose: PropTypes.func
}

export default ShowAllCompanyInitiative;

