import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import { StyledBody, StyledModal, StyledTitle } from '../SingleCompanyInitiative/styles'
import { ImageUrl } from '../../../utils/constants';
import { BASE_URL } from '../../../utils/config';
import { deleteInitiative } from '../../../redux/constants/apiConstants';
import { toast } from 'react-toastify';

class DeletePopup extends Component {

  onDelete = async(selectedInitiative) => {
    const companyId = this.props.companyId;
    const initiativeId = selectedInitiative.id;
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let url = BASE_URL + `/${deleteInitiative}/${companyId}/${initiativeId}`;
    await fetch(url, { method: "DELETE", headers: { "Content-Type": "application/json", AUTHTOKEN: AuthToken }})
      .then((res) => {
        res
          .json()
          .then(() => {
            if (res.status === 200) {
              toast.success("Deleted Successfully");
              this.props.successFunc();
              this.close();
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  close = () => {
    this.props.onClose(false)
  }

  render() {
    const { showModal,onClose,selectedInitiative} = this.props
    return (
      <StyledModal transform={1} show={showModal}   newWidth={"88.7%"}  newBg={'newBg'} >
        <Modal.Header closeButton onHide={() => onClose(false)} border={'border'} style={{background:'#005c87'}}>
          <StyledTitle textAlign={'textAlign'}>Delete Confirmation</StyledTitle>
        </Modal.Header>
        <StyledBody>
          <div style={{width:'100%',textAlign:'center',margin:'5px 0px 35px 0px'}}>
            <img src={ImageUrl +"/"+ selectedInitiative.initiative_image} alt="" style={{width:'106px',height:'106px',borderRadius:'3px',marginBottom:'15px'}}/>
            <p style={{color:"#005c87",fontSize:'18px',fontFamily:'rubik'}}>Are you sure you want to Delete <br/><b>{selectedInitiative.initiative_name} Initiative?</b></p>
          </div>
          <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',gap:'15px'}}>
            <button onClick={() =>this.onDelete(selectedInitiative)} style={{width:'200px',height:'50px',display:'flex',justifyContent:'center',alignItems:'center',background:'#005c87',color:'white',fontFamily:'rubik-medium',fontSize:'18px',border:"none",borderRadius:'3px',boxShadow: "1px 4px 10px 0px #005C8766"}}>Yes, Delete It!</button>
            <button onClick={() => onClose(false)} style={{width:'200px',height:'50px',display:'flex',justifyContent:'center',alignItems:'center',background:'white',color:'#669db7',fontFamily:'rubik-medium',fontSize:'18px',border:"1px solid #669db7",borderRadius:'3px'}}>Cancel</button>
          </div>
        </StyledBody>
      </StyledModal>    
    )
  }
}

DeletePopup.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  history: PropTypes.object,
  selectedInitiative:PropTypes.object,
  companyId:PropTypes.any,
  successFunc:PropTypes.func,
};
  
export default DeletePopup;